import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Config } from '../../config/Config';
import { configApi } from '../../config/configApi';
import { ai } from './TelemetryService';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component {
    state = {
        initialized: false
    };

    async componentDidMount() {
        let connString = await configApi(`${Config.server_url}:${Config.port}`).then(res => res.appInsightsConnString)

        const {history} = this.props;
        const {initialized} = this.state;
        const AppInsightsConnectionString = connString; // PUT YOUR KEY HERE
        if (!Boolean(initialized) && Boolean(AppInsightsConnectionString) && Boolean(history)) {
            ai.initialize(AppInsightsConnectionString, history);
            this.setState({initialized: true});
        }

        this.props.after();
    }

    render() {
        const {children} = this.props;
        return (
            <Fragment>
                {children}
            </Fragment>
        );
    }
}

// @ts-ignore
export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider));