import Axios from "axios";

export async function configApi(server_url: string) {
  return Axios.get<ConfigResponse>(server_url + '/api/clientConfig')
    .then(res => {
      return res.data;
    });
}

export type ConfigResponse = {
  appInsightsConnString: string;
};
