import { PulseLoader } from 'react-spinners';

export function InlineLoading(props: { loading?: boolean } = { loading: true }) {
    return (
        <PulseLoader
            cssOverride={{display: "block", margin: "0", borderColor: "var(--primary)"}}
            size={15}
            color={"var(--primary)"}
            loading={props.loading ?? true}
        />
    );
}
export function ScreenLoading(props: { loading?: boolean } = { loading: true }) {
    return (
        <div className="loader">
            <InlineLoading loading={props.loading} />
        </div>
    );
}