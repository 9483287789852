import React from "react";
import { useHistory } from "react-router-dom";
import { AppState, Auth0Provider } from "@auth0/auth0-react";

type Auth0Props = {children?: React.ReactNode}

function Auth0ProviderWithHistory({ children }: Auth0Props) {

  const history = useHistory();

  const onRedirectCallback = (appState: AppState | undefined) => {

    let params = (new URL(window.location.toString())).searchParams;
    let questionnaire  = params.get("questionnaire");

    const path = questionnaire
      ? `/questionnaires/${questionnaire}`
      : appState?.returnTo || window.location.pathname;

    history.push(path);
  };

  let params = (new URL(window.location.toString())).searchParams;
  let questionnaire  = params.get("questionnaire");

  const redirectUri = questionnaire
  ? `${window.location.origin}?questionnaire=${questionnaire}`
    : window.location.origin;

  return (
    <Auth0Provider
        domain="legaltech.eu.auth0.com"
        clientId="EuFVIMtB0M8DN0rT91ojaqhu8Dzi3iwS"
        redirectUri={redirectUri}
        onRedirectCallback={onRedirectCallback}
        audience="https://api.360-advisor.com/"
        scope="read:current_user 360:demo"
        useRefreshTokens={true}
        cacheLocation="localstorage"
        maxAge={60 * 60 * 10}
    >
      {children}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithHistory;