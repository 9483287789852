import { configApi } from "./configApi";

const prod = {
  VersionInfo: '1.104',
  port: 443,
  server_url: server_url(),
  globalization_url: "https://globalize.360-advisor.com/api",
  api_prefix: "/api",
  languages: [
    { value: "dk", name: "Dansk" },
    { value: "en", name: "English" },
    { value: "de", name: "Deutsch" },
    { value: "sv", name: "Svenska" },
    { value: "no", name: "Norsk" },
    { value: "fi", name: "Suomi" },
    { value: "pl", name: "Polski" },
    { value: "nl", name: "Nederlands" },
    { value: "cs", name: "Čeština" },
    { value: "fr", name: "Français" }
  ],
  resourceSet: ["360Shared"],
  applicationinsights_connectionsString: "",
  wizard_based_conditions: true,
};

const dev = {
  VersionInfo: 'DEVELOPER',
  port: 50798,
  server_url: "https://localhost",
  // port: 443,
  // server_url: "https://ltddev-aml.api.360-advisor.com",
  // server_url: "https://ltddev-dpo.api.360-advisor.com",
  // server_url: "https://ltddev-kreston.api.360-advisor.com",
  // server_url: "https://ltddev-ciso.api.360-advisor.com",
  globalization_url: "https://globalize.360-advisor.com/api",
  api_prefix: "/api",
  languages: [
    { value: "dk", name: "Dansk" },
    { value: "en", name: "English" },
    { value: "de", name: "Deutsch" },
    { value: "sv", name: "Svenska" },
    { value: "no", name: "Norsk" },
    { value: "fi", name: "Suomi" },
    { value: "pl", name: "Polski" },
    { value: "nl", name: "Nederlands" },
    { value: "cs", name: "Čeština" },
    { value: "fr", name: "Français" }
  ],
  resourceSet: ["360Shared"],
  applicationinsights_connectionsString: "",
  wizard_based_conditions: true,
};

function server_url() {
  return 'https://' + window.location.host.split('.', 1)[0] + '.api.360-advisor.com';
}



export let Config = process.env.NODE_ENV === "development" ? dev : prod;
